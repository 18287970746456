import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

//Vue.use(require('./lib/textae/dist/lib/textae-10.1.0'))
//import textae from './lib/textae/dist/lib/textae-10.1.0'
///console.log(textae)

loadFonts()

// Google Analytics Configuration
router.beforeEach((to, from, next) => {
  window.gtag('config', 'G-XL2PSWB04T', {
    page_path: to.fullPath,
    page_title: to.name
  })
  next()
})

createApp(App)
  .use(router)
  .use(vuetify)
  .mount('#app')
