import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/main/HomeView.vue'
import MainView from '../views/main/MainView.vue'

const routes = [
  {
    path: '/main',
    name: 'main',
    component: MainView,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/main/HomeView.vue')
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/main/AboutView.vue')
      },
    ]
  },
  {
    path: '/about',
    name: 'aboutDirect',
    component: () => import('../views/main/AboutView.vue')
  },
  {
    path: '/anno-view',
    name: 'annoView',
    component: () => import('../views/AnnotationView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
