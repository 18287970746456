<template>
  <div>
    <v-navigation-drawer v-model="drawer" temporary>
      <v-list>
        <v-list-item to="/" title="Home"></v-list-item>
        <v-list-item to="/about" title="About"></v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="teal-darken-4">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>ArTraDB</v-app-bar-title>
      <v-spacer></v-spacer>
      <a
        v-for="logo in logos"
        :key="logo.image"
        :href="logo.url"
        target="_blank"
        rel="noopener noreferrer"
        class="mx-2"
        style="display: block; height: 40px; width: 100px;"
      >
        <v-img
          :src="require(`@/assets/${logo.image}`)"
          :height="40"
          :width="100"
          contain
        ></v-img>
      </a>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      drawer: false,
      logos: [
        {
          image: 'logo-sib.png',
          url: 'https://www.sib.swiss/'
        },
        {
          image: 'logo-unil.png',
          url: 'https://www.unil.ch/'
        },
        {
          image: 'logo-supsi.png',
          url: 'https://www.supsi.ch/'
        },
        {
          image: 'logo-plazi.png',
          url: 'https://plazi.org/'
        },
        {
          image: 'logo-snsf.png',
          url: 'https://www.snf.ch/'
        }
      ]
    }
  }
}
</script>

