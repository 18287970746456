<template>
  <v-footer class="bg-teal-darken-4">
    <v-row justify="center" align="center">
      <v-col cols="12">
        <div class="d-flex justify-center flex-wrap">
          <a
            v-for="logo in logos"
            :key="logo.image"
            :href="logo.url"
            target="_blank"
            rel="noopener noreferrer"
            class="ma-2"
            style="display: block; height: 50px; width: 120px;"
          >
            <v-img
              :src="require(`@/assets/${logo.image}`)"
              :height="50"
              :width="120"
              contain
            ></v-img>
          </a>
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        {{ new Date().getFullYear() }} — <strong>ArTraDB</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      logos: [
        {
          image: 'logo-sib.png',
          url: 'https://www.sib.swiss/'
        },
        {
          image: 'logo-unil.png',
          url: 'https://www.unil.ch/'
        },
        {
          image: 'logo-supsi.png',
          url: 'https://www.supsi.ch/'
        },
        {
          image: 'logo-plazi.png',
          url: 'https://plazi.org/'
        },
        {
          image: 'logo-snsf.png',
          url: 'https://www.snf.ch/'
        }
      ]
    }
  }
}
</script>