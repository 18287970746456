<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <cookie-consent />
  </v-app>
</template>

<script>
import CookieConsent from './components/CookieConsent.vue'

export default {
  name: 'App',
  components: {
    CookieConsent
  },
  mounted() {
    // Google Analytics Script
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-XXXXXXXXXX`
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []
    window.gtag = function() {
      window.dataLayer.push(arguments)
    }
    window.gtag('js', new Date())
    window.gtag('config', 'G-XL2PSWB04T')
  }
}
</script>