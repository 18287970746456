<template>
  <v-snackbar
    v-model="show"
    :timeout="-1"
    location="bottom"
    class="cookie-consent"
  >
    <span>
      This website uses cookies to enhance the user experience.
    </span>

    <template v-slot:actions>
      <v-btn
        color="blue"
        variant="text"
        @click="acceptCookies"
      >
        Accept
      </v-btn>
      <v-btn
        color="white"
        variant="text"
        @click="declineCookies"
      >
        Decline
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'CookieConsent',
  data() {
    return {
      show: false
    }
  },
  mounted() {
    // Check if user has already made a choice
    const consent = localStorage.getItem('cookieConsent')
    if (consent === null) {
      this.show = true
    } else if (consent === 'accepted') {
      this.initializeAnalytics()
    }
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookieConsent', 'accepted')
      this.show = false
      this.initializeAnalytics()
    },
    declineCookies() {
      localStorage.setItem('cookieConsent', 'declined')
      this.show = false
      // Optionally, you can add code here to disable analytics
      window['ga-disable-G-XL2PSWB04T'] = true
    },
    initializeAnalytics() {
      // Google Analytics Script
      const script = document.createElement('script')
      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-XL2PSWB04T`
      document.head.appendChild(script)

      window.dataLayer = window.dataLayer || []
      window.gtag = function() {
        window.dataLayer.push(arguments)
      }
      window.gtag('js', new Date())
      window.gtag('config', 'G-XL2PSWB04T')
    }
  }
}
</script>

<style scoped>
.cookie-consent a {
  text-decoration: underline;
  color: #2196F3; /* Material Design Blue */
}
</style> 